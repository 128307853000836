/** *************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/** *************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

 // (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 // (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 // (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

 /*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
(window as any).__Zone_enable_cross_context_check = true;

/** *************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.



/** *************************************************************************************************
 * APPLICATION IMPORTS
 */

// Add global to window, assigning the value of window itself.
// See https://github.com/fxmontigny/ng2-ace-editor/issues/87
(window as any).global = window;



// Some IE and Edge functions that are missing
// Add ECMA262-5 method binding if not supported natively
//
if (!Function.prototype.bind) {
  Function.prototype.bind = function (owner) {
    var that = this;
    if (arguments.length <= 1) {
      return function () {
        return that.apply(owner, arguments);
      };
    } else {
      var args = Array.prototype.slice.call(arguments, 1);
      return function () {
        return that.apply(owner, arguments.length === 0 ? args : args.concat(Array.prototype.slice.call(arguments)));
      };
    }
  };
}

// Add ECMA262-5 string trim if not supported natively
//
if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^\s+/, '').replace(/\s+$/, '');
  };
}

// Add ECMA262-5 Array methods if not supported natively
//
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (find, i /* opt*/) {
    if (i === undefined) { i = 0; }
    if (i < 0) { i += this.length; }
    if (i < 0) { i = 0; }
    for (var n = this.length; i < n; i++) {
      if (i in this && this[i] === find) { return i; }
    }
    return -1;
  };
}
if (!Array.prototype.lastIndexOf) {
  Array.prototype.lastIndexOf = function (find, i /* opt*/) {
    if (i === undefined) { i = this.length - 1; }
    if (i < 0) { i += this.length; }
    if (i > this.length - 1) { i = this.length - 1; }
    for (i++; i-- > 0;) { /* i++ because from-argument is sadly inclusive */
      if (i in this && this[i] === find) { return i; }
    }
    return -1;
  };
}
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (action, that /* opt*/) {
    for (var i = 0, n = this.length; i < n; i++) {
      if (i in this) { action.call(that, this[i], i, this); }
    }
  };
}
if (!Array.prototype.map) {
  Array.prototype.map = function (mapper, that /* opt*/) {
    var other = new Array(this.length);
    for (var i = 0, n = this.length; i < n; i++) {
      if (i in this) { other[i] = mapper.call(that, this[i], i, this); }
    }
    return other;
  };
}
if (!Array.prototype.filter) {
  Array.prototype.filter = function (filter, that /* opt*/) {
    var other = [], v;
    for (var i = 0, n = this.length; i < n; i++) {
      if (i in this && filter.call(that, v = this[i], i, this)) { other.push(v); }
    }
    return other;
  };
}
if (!Array.prototype.every) {
  Array.prototype.every = function (tester, that /* opt*/) {
    for (var i = 0, n = this.length; i < n; i++) {
      if (i in this && !tester.call(that, this[i], i, this)) { return false; }
    }
    return true;
  };
}
if (!Array.prototype.some) {
  Array.prototype.some = function (tester, that /* opt*/) {
    for (var i = 0, n = this.length; i < n; i++) {
      if (i in this && tester.call(that, this[i], i, this)) { return true; }
    }
    return false;
  };
}
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    enumerable: false,
    value: function (obj) {
      var newArr = this.filter(function (el) {
        return el == obj;
      });
      return newArr.length > 0;
    }
  });
}
